import { Redirect, Route, Switch } from 'react-router-dom';
import { AboutMeScreen, Path as AboutMePath } from '../screens/AboutMeScreen';
import { ClothesScreen, Path as ClothesPath } from '../screens/ClothesScreen';
import { EmbroideryScreen, Path as EmbroideryPath } from '../screens/EmbroideryScreen';

const appBaseRouteKey = "";
export const HomePath = "/";

export default function Routes(){
    return(
        <Switch>
            <Route path={appBaseRouteKey + HomePath} exact render={() => <Redirect to={appBaseRouteKey + ClothesPath} />} />
            <Route exact path={appBaseRouteKey + ClothesPath} render={() => <ClothesScreen/>} />
            <Route exact path={appBaseRouteKey + EmbroideryPath} render={() => <EmbroideryScreen/>} />
            <Route exact path={appBaseRouteKey + AboutMePath} render={() => <AboutMeScreen/>} />
            <Route render={() => <Redirect to={appBaseRouteKey + HomePath} />} />
        </Switch>
    );
}