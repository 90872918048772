import React, { useEffect } from "react";
import { DeviceContextConsumer, DeviceType } from "../contexts/DeviceContext";
import { IconButton, Portal } from '@material-ui/core';
import useTheme from "@material-ui/core/styles/useTheme";
import { useState } from "react";
import { useRef } from "react";
import useHover from "../hooks/useHover";
import useTouched from "../hooks/useTouched";

interface FloatingFacebookLinkProps {
    top: number;
    left: number;
    href: string;
}

export const FloatingFacebookLink = (props: FloatingFacebookLinkProps) => {
    const container = React.useRef(null);

    return(
        <div>
            <Portal container={container.current}>
                <FloatingFacebookLinkContent {...props}/>
            </Portal>
            <div ref={container}/>
        </div>
    );
}

const FloatingFacebookLinkContent = (props: FloatingFacebookLinkProps) => {
    const theme = useTheme();
    const [scrollTop, setScrollTop] = useState(0);
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const isTouched = useTouched(hoverRef);
    
    useEffect(() => {
        const onScroll = (e: any) => {
            setScrollTop(e.target.documentElement.scrollTop);
        };

        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    }, [scrollTop]);

    return(
    <DeviceContextConsumer>
    {context =>
        <>
            <IconButton 
                ref={hoverRef}
                target="_blank"
                href={props.href}
                style={{
                    transition: 'position 300ms',
                    paddingTop: '0px',
                    padding: '0px',
                    paddingBottom: '5px',
                    borderTop: 'unset',
                    WebkitTransform: `rotate(-90deg) translate(-50%, -50%)`,
                    msTransform: `rotate(-90deg) translate(-50%, -50%)`,
                    transform: `rotate(-90deg) translate(-50%, -50%)`,
                    position: 'absolute',
                    top: props.top + scrollTop,
                    left: 0,
                    zIndex: 500,
                    marginLeft: context === DeviceType.isDesktopOrLaptop ? '-25px' : '-19px',
                    borderTopRightRadius: '0px',
                    borderTopLeftRadius: '0px',
                    borderBottomRightRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    background: 'white',
                    boxShadow: theme.shadows[(context === DeviceType.isDesktopOrLaptop ? 9 : 3)],
                }}>
                 <img
                    src={"/facebook-dark.png"}
                    alt={"facebook-dark"}
                    style={{
                        opacity: context === DeviceType.isDesktopOrLaptop ? (isHover ? '0.67' : '1') : isTouched ? '0.67' : '1',
                        height: context === DeviceType.isDesktopOrLaptop ? '40px': '30px',
                        objectFit: 'contain', 
                        maxHeight: '100%',
                        border: context === DeviceType.isDesktopOrLaptop ? ("1px " + (isHover ? "solid" : "solid") + `${theme.palette.primary.main}`) : "1px solid " + `${theme.palette.primary.main}`,
                        borderTop: 'unset',
                        backgroundColor: 'transparent',
                        borderTopRightRadius: '0px',
                        borderTopLeftRadius: '0px',
                        borderBottomRightRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        marginBottom: '-5px'
                    }}
                />
            </IconButton>
        </>
    }
    </DeviceContextConsumer>
    );
}