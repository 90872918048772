import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { DeviceContextConsumer, DeviceType } from "../contexts/DeviceContext";
import sizeMe from 'react-sizeme';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { HomePath } from "../routes/Routes";
import MenuButtons from '../desktop/MenuButtons';
import { MenuWithItems } from '../mobile/MenuWithItems';
import useTheme from "@material-ui/core/styles/useTheme";
import AskForm from './AskForm';
import AddressForm from './AddressForm';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function TopMenu() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div 
      className={classes.root} 
      style={{
        background: 'white',
        position:'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        zIndex: 1000}}
    >
      <AppBar position="sticky" style={{
        backgroundColor: 'transparent',
        boxShadow: theme.shadows[2],
      }}>
          <DeviceContextConsumer>
            {context => (
              <Toolbar style={{
                color:'#303336', 
                backgroundColor:'transparent', 
                paddingLeft: context === DeviceType.isDesktopOrLaptop ? '32px' : '10px', 
                paddingRight: context === DeviceType.isDesktopOrLaptop ? '32px' : '10px'}}>
                {context === DeviceType.isTabletOrMobile && (
                  <MenuWithItems/>
                )}
                  <Typography
                    variant={context === DeviceType.isDesktopOrLaptop ? "h4" : "h4"}
                    className={classes.title}
                    align={context === DeviceType.isDesktopOrLaptop ? "left" : 'center'}
                    style={{
                      WebkitTapHighlightColor: 'transparent',
                      fontWeight: 'bold',
                      fontSize: context === DeviceType.isDesktopOrLaptop ? '33px':'22px',
                      textAlign: context === DeviceType.isDesktopOrLaptop ? "left" : 'center'}}>
                        <StyledLink 
                          className={context === DeviceType.isDesktopOrLaptop ? "pointerOverEffect" : ""}
                          to={HomePath}>
                            <img src={'/logo-small.png'} alt={"logo"} style={{
                              WebkitTapHighlightColor: 'transparent',
                              paddingTop: '10px',
                              height: context === DeviceType.isDesktopOrLaptop ? '44px':'33px',
                              objectFit: 'contain'
                            }}/>
                        </StyledLink>
                  </Typography>
                  {context === DeviceType.isDesktopOrLaptop && (
                    <MenuButtons />
                  )}
                  <AddressForm style={{
                    paddingLeft: context === DeviceType.isDesktopOrLaptop ? '20px' : 'unset'
                  }}/>
                  <AskForm style={{
                    paddingLeft: context === DeviceType.isDesktopOrLaptop ? '20px' : 'unset'
                  }}/>          
              </Toolbar>
            )}
          </DeviceContextConsumer>
      </AppBar>
    </div>
  );
}

export const StyledLink = styled(Link)
`
  text-decoration: none;
  -webkit-tap-highlight-color: 'transparent';
  color: inherit;
  :hover, :active {
    color: inherit;
 }
`;

export default sizeMe({ monitorHeight: true })(TopMenu);
