export type ItemDetails = {
    img: Array<string>;
    title: string;
    shortDescription: string;
    description: string;
    id: string;
    categoryId: string;
    isPhysicalItem: boolean;
    inFuture: boolean;
}

export type CategoryType = {
    id: string;
}

export const Categories: Array<CategoryType> = [
    {
        id: "odziez",
    },
    {
        id: "haft",
    }
]

export const tileData: Array<ItemDetails> = [
    {
        inFuture: false,
        img: ['/hafty/1.jpeg'],
        title: 'Wykonanie 1',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "",
        id: "2",
        categoryId: Categories[1].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/hafty/2.jpeg'],
        title: 'Wykonanie 2',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "",
        id: "3",
        categoryId: Categories[1].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/hafty/3.jpeg'],
        title: 'Wykonanie 3',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "",
        id: "4",
        categoryId: Categories[1].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/hafty/4.jpeg'],
        title: 'Wykonanie 4',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "",
        id: "5",
        categoryId: Categories[1].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/hafty/5.jpeg'],
        title: 'Wykonanie 5',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "",
        id: "6",
        categoryId: Categories[1].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/hafty/6.jpeg'],
        title: 'Wykonanie 6',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "",
        id: "7",
        categoryId: Categories[1].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/hafty/7.jpeg'],
        title: 'Wykonanie 7',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "",
        id: "8",
        categoryId: Categories[1].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/hafty/8.jpeg'],
        title: 'Wykonanie 8',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "",
        id: "9",
        categoryId: Categories[1].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/hafty/9.jpeg'],
        title: 'Wykonanie 9',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "",
        id: "10",
        categoryId: Categories[1].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/hafty/10.jpeg'],
        title: 'Wykonanie 10',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "",
        id: "11",
        categoryId: Categories[1].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/hafty/11.jpeg'],
        title: 'Wykonanie 11',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "",
        id: "12",
        categoryId: Categories[1].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/hafty/12.jpeg'],
        title: 'Wykonanie 12',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "",
        id: "13",
        categoryId: Categories[1].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/polary/0.jpg', '/polary/1.jpg','/polary/2.jpg','/polary/3.jpg','/polary/4.jpg','/polary/5.jpg','/polary/6.jpg','/polary/7.jpg','/polary/8.jpg','/polary/9.jpg','/polary/10.jpg','/polary/11.jpg','/polary/12.jpg','/polary/13.jpg','/polary/14.jpg','/polary/15.jpg'],
        title: 'Polary',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "Bluzy polarowe dostępne w gramaturach 270 oraz 420 w różnych kolorach.",
        id: "14",
        categoryId: Categories[0].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/polowki/0.jpg', '/polowki/1.jpg'],
        title: 'Koszulki polo',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "Koszulki polo z długim i krótkim rękawem o gramaturze 170 oraz 210.",
        id: "15",
        categoryId: Categories[0].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/spodnice/3.jpg','/spodnice/4.jpg'],
        title: 'Spódnice',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "Możliwość uszycia spódnic z różnorakich materiałów.",
        id: "16",
        categoryId: Categories[0].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/spodnie/1.jpg', '/spodnie/2.jpg'],
        title: 'Spodnie',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "Możliwość uszycia spodni z różnorakich materiałów.",
        id: "17",
        categoryId: Categories[0].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/tshirty/1.jpg'],
        title: 'T-shirty',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "T-shirt z gramatury 170 w kolorze fluo z pasami odblaskowymi. 100% bawełna.",
        id: "18",
        categoryId: Categories[0].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/fartuszki/0.jpg', '/fartuszki/1.jpg','/fartuszki/2.jpg','/fartuszki/3.jpg','/fartuszki/4.jpg','/fartuszki/5.jpg','/fartuszki/6.jpg','/fartuszki/7.jpg','/fartuszki/8.jpg'],
        title: 'Fartuszki, żakiety',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "Możliwość wykonania fartuszków w wielu fasonach oraz konfiguracjach kolorystycznych.",
        id: "19",
        categoryId: Categories[0].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/zawodowe/11.jpg','/zawodowe/1.jpg','/zawodowe/2.jpg','/zawodowe/3.jpg','/zawodowe/4.jpg','/zawodowe/5.jpg','/zawodowe/6.jpg','/zawodowe/7.jpg','/zawodowe/8.jpg','/zawodowe/9.jpg','/zawodowe/10.jpg','/zawodowe/13.jpg','/zawodowe/12.jpg'],
        title: 'Ubrania zawodowe',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "Spodnie na szelkach, spodnie do pasa, bluzy oraz kamizelki ocieplane.",
        id: "20",
        categoryId: Categories[0].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/zapaski/1.jpg','/zapaski/2.jpg','/zapaski/3.jpg'],
        title: 'Zapaski',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "Zapaski kelnerskie oraz kucharskie.",
        id: "21",
        categoryId: Categories[0].id,
        isPhysicalItem: true
    },
    {
        inFuture: false,
        img: ['/koce/1.jpg'],
        title: 'Koce polarowe',
        shortDescription: 'ROZMIAROWKA HTML',
        description: "Kocyki polarowe zakończone lamówką.",
        id: "22",
        categoryId: Categories[0].id,
        isPhysicalItem: true
    },
 ];