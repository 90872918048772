import React from 'react';
import { lazy, Suspense } from 'react';
import { Categories } from "../common/Offer";
import { LoadingInProgress } from '../molecules/LoadingInProgress';
import { ContentLayout } from '../layouts/MainLayout';

const ClothesScreenContent = lazy(() => import("../common/ClothesScreenContent"));

export const Path = "/embroidery";
export const Title = "Hafty";

export function EmbroideryScreen(){
    return (
        <Suspense fallback={
            <LoadingInProgress/>
        }>
            <ContentLayout>
                <ClothesScreenContent categoryType={Categories[1]} color={'secondary'}/>
            </ContentLayout>
        </Suspense>
    );
}