import { Title as AboutMeTitle, Path as AboutMePath } from "../screens/AboutMeScreen";
import { Title as ClothesTitle, Path as ClothesPath } from "../screens/ClothesScreen";
import { Title as EmbroideryTitle, Path as EmbroideryPath } from "../screens/EmbroideryScreen";

export const appBaseRouteKey = "";

export enum configSectionType {
    'divider',
    'link'
}

export type configSection = {
    title: string;
    api: string;
    type: configSectionType;
}

export function GetFullPathTo(title: string): string{
    const result = OrderedSectionsConfiguration.findIndex((p: configSection) => p.title === title);
    return OrderedSectionsConfiguration[result].api;
}

export const OrderedSectionsConfiguration: Array<configSection> = [
    {
        title: ClothesTitle,
        api: appBaseRouteKey + ClothesPath,
        type: configSectionType.link
    },
    {
        title: "",
        api: "",
        type: configSectionType.divider
    },
    {
        title: EmbroideryTitle,
        api: appBaseRouteKey + EmbroideryPath,
        type: configSectionType.link
    },
    {
        title: "",
        api: "",
        type: configSectionType.divider
    },
    {
        title: AboutMeTitle,
        api: appBaseRouteKey + AboutMePath,
        type: configSectionType.link
    }
];