import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'rgba(100,100,100,0.15)',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  title: {
    flexGrow: 1,
    fontSize: 10,
    fontFamily: 'Signika'
  }
}));

export const Footer = () =>  {
  const classes = useStyles();

  return (
        <footer className={classes.root} style={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          width: '100%'}}>
          <div className={classes.title}>
            <span>
            &copy; profipromo.pl 2008-2021
          </span>
          </div>
        </footer>
    )
}