import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

export const errorColor = 'rgba(206, 17, 38, 1)';

function getShuffledArr (arr: Array<any>){
  return [...arr].map( (_, i, arrCopy) => {
      var rand = i + ( Math.floor( Math.random() * (arrCopy.length - i) ) );
      [arrCopy[rand], arrCopy[i]] = [arrCopy[i], arrCopy[rand]]
      return arrCopy[i]
  })
};

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

export function RGBToHex(rgb: any) {
  let sep = rgb.indexOf(",") > -1 ? "," : " ";
  rgb = rgb.substr(4).split(")")[0].split(sep);

  let r = (+rgb[0]).toString(16),
      g = (+rgb[1]).toString(16),
      b = (+rgb[2]).toString(16);

  if (r.length === 1)
    r = "0" + r;
  if (g.length === 1)
    g = "0" + g;
  if (b.length === 1)
    b = "0" + b;

  return "#" + r + g + b;
};

export function RGBToRGBA(rgb: any, alpha: any) {
  let sep = rgb.indexOf(",") > -1 ? "," : " ";
  rgb = rgb.substr(4).split(")")[0].split(sep);

  return "rgba(" + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ',' + alpha + ")";
};

export const cacheImages = async (srcArray: Array<string>) =>{
  const promises = await srcArray.map((src: string) => {
    return new Promise(function (resolve, reject) {
      const img = new Image();

      img.src = src;
      img.onload =  () => resolve(true);
      img.onerror = () => reject();
    });
  });

  await Promise.all(promises);
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0B3976",
    },
    secondary: {
      main: "#900b1a",
    },
  },
  typography: {
    fontFamily: [
      'Signika', 'sans-serif'
    ].join(',')
  },
  overrides: {
    MuiTableContainer: {
      root: {
        overflowX: 'unset'
      },
    },
  },
});

const CustomMuiThemeProvider = (props: any) => {
    return (
    <MuiThemeProvider theme={theme}>
        {props.children}
    </MuiThemeProvider>
    );
}

export default CustomMuiThemeProvider;

export function GetRandomBackground(): string {
  const angles: Array<string> = [
    "217deg",
    "127deg",
    "336deg"
  ];

  const setOfColors: Array<string> = [
    "linear-gradient(" + angles[getRandomInt(0, angles.length-1)] + "," + RGBToRGBA(theme.palette.secondary.dark, 0.8) + "," + RGBToRGBA(theme.palette.secondary.dark, 0) + " 70.71%)",
    "linear-gradient(" + angles[getRandomInt(0, angles.length-1)] + "," + RGBToRGBA(theme.palette.primary.dark, 0.8) + "," + RGBToRGBA(theme.palette.primary.dark, 0) + " 70.71%)",
    "linear-gradient(" + angles[getRandomInt(0, angles.length-1)] + "," + RGBToRGBA(theme.palette.secondary.light, 0.8) + "," + RGBToRGBA(theme.palette.secondary.light, 0) + " 70.71%)",
    "linear-gradient(" + angles[getRandomInt(0, angles.length-1)] + "," + RGBToRGBA(theme.palette.primary.light, 0.8) + "," + RGBToRGBA(theme.palette.primary.light, 0) + " 70.71%)"
  ];

  const shuffled: Array<string> = getShuffledArr(setOfColors);
  
  return shuffled.join(',');
}