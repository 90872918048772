import React from 'react';
import { lazy, Suspense } from 'react';
import { LoadingInProgress } from '../molecules/LoadingInProgress';
import { ContentLayout } from '../layouts/MainLayout';

const AboutMeScreenContent = lazy(() => import("../common/AboutMeScreenContent"));

export const Path = "/aboutus";
export const Title = "O nas";

export function AboutMeScreen(){
    return (
        <Suspense fallback={
            <LoadingInProgress/>
        }>
            <ContentLayout>
                <AboutMeScreenContent/>
            </ContentLayout>
        </Suspense>
    );
}