import React, { useEffect, useState } from 'react';

type Address = {
    showAddress: () => void;
    hideAddress: () => void;
    isAddressOpen: () => boolean;
};

const AddressContext = React.createContext<Address>({} as Address);

const AddressContextProvider = (props: any) => {
    const [ isBottomOpened, setIsBottomOpened ] = useState<boolean>(false);

    useEffect(()=>{
      setIsBottomOpened(false);
    }, []);

    const addressContext: Address = ({
      showAddress: () => {
        setIsBottomOpened(true);
      },
      hideAddress: () => {
        setIsBottomOpened(false);
      },
      isAddressOpen: () => {
        return isBottomOpened;
      }
    });
  
    return <AddressContext.Provider value={addressContext}>{props.children}</AddressContext.Provider>;
  }

export { AddressContextProvider, AddressContext };