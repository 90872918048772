import React from 'react';
import Button from '@material-ui/core/Button';
import { configSection, configSectionType, OrderedSectionsConfiguration, GetFullPathTo } from "../routes/RouterConfiguration";

export default function MenuButtons(props: any) {
    return (
    <div 
      {...props}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        verticalAlign: 'center',
        fontSize: '20px'
    }}>
      {OrderedSectionsConfiguration.map((section: configSection, index: number) => {
        if(section.type === configSectionType.divider){
          return (
            <div style={{
              paddingLeft: '20px',
              paddingRight: '20px',
              height: '26px',
                width: '0px',
                alignSelf: 'center',
            }}>
              <div style={{
                height: '26px',
                width: '0px',
                borderLeft: '1px solid black'
              }}/>
            </div>);
        }else{
          return <Button 
            className={"pointerOverEffect"}
            tabIndex={index}
            key={index} 
            color="inherit" 
            style={{
              fontSize: 'inherit',
              fontWeight: 'bold'}} 
            href={GetFullPathTo(section.title)}>
              {section.title.toUpperCase()}
            </Button>
        }
      })}
    </div>);
}
