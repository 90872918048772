import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Guid } from 'guid-typescript';
import React, { useState } from 'react';
import { useContext, useEffect } from 'react';
import { DeviceContextConsumer, DeviceType } from './DeviceContext';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

export enum NotificationType {
  error = 'error',
  success = 'success'
}

export type Notification = {
  id: string;
  content: string;
  type: NotificationType;
}

type Notify = {
    add: (content: string, type: NotificationType) => void;
    remove: (id: string) => void;
    notifications: Array<Notification>;
};

const NotificationContext = React.createContext<Notify>({} as Notify);

const NotificationContextProvider = (props: any) => {
  const [ notifications, setNotifications ] = useState<Array<Notification>>(new Array<Notification>());

    const contactContext: Notify = ({
      add: (content: string, type: NotificationType) => {
        const id = Guid.create().toString();
        const newOne: Notification = {
          content: content,
          type: type,
          id: id};
        
          notifications.push(newOne);
      },
      remove: (id: string) => {
        setNotifications(notifications.filter((p: Notification) => p.id !== id));
      },
      notifications: notifications
    });
  
    return <NotificationContext.Provider value={contactContext}>{props.children}</NotificationContext.Provider>;
  }

export { NotificationContextProvider, NotificationContext };

export const NotificationContainer = (props: any) =>{
  const [ children, setChildren ] = useState<Array<JSX.Element>>(new Array<JSX.Element>());
  const { notifications } = useContext(NotificationContext);

  useEffect(()=>{
    setChildren(notifications?.map((p: Notification, index: number)=>{
      return (
        <NotificationItem item={p} index={index}/>
        );
    }));
  }, [ notifications.length ]);
   
    return(
      <div {...props}>
              {children}
      </div>
    );
  }


type NotificationItemProps = {
  item: Notification;
  index: number;
}

const NotificationItem = (props: NotificationItemProps) =>{
const theme = useTheme();
const { item } = props;
const { remove } = useContext(NotificationContext);

const choosenColor = item.type === NotificationType.success ? theme.palette.success.dark : theme.palette.warning.dark;

setTimeout(()=>{
    remove(item.id);
  }, 4000);

return (
  <DeviceContextConsumer>
  {context =>
    <div
      style={{
        position: 'relative',
        left: '-50%',
        transition: 'all 0.4s ease',
        msTransition: 'all 0.4s ease',
        MozTransition: 'all 0.4s ease',
        WebkitTransition: 'all 0.4s ease',
        borderRadius: '5px',
        backgroundColor: 'white',
        padding: context === DeviceType.isDesktopOrLaptop ? '20px' : '10px',
        color: `${choosenColor}`,
        border: `1px solid ${choosenColor}`
      }}
      key={props.index}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div style={{
            textAlign: 'center',
            paddingLeft: context === DeviceType.isDesktopOrLaptop ? '20px' : '10px',
            paddingRight: context === DeviceType.isDesktopOrLaptop ? '20px' : '10px'
          }}>
            {item.type === NotificationType.success ? (
              <CheckCircleIcon 
                fontSize={context === DeviceType.isDesktopOrLaptop ? "large" : "small"} />
            ):(
              <ErrorIcon
                fontSize={context === DeviceType.isDesktopOrLaptop ? "large" : "small"} />
            )}
          </div>
          <Typography 
            style={{
              fontSize: context === DeviceType.isDesktopOrLaptop ? '22px' : '14px'
          }}>
            {item.content}
          </Typography>
        </div>
      </div>
  }
  </DeviceContextConsumer>
  );
}

const useNotification = () => {
  const { add } = useContext(NotificationContext);

  const addNotification = (content: string, type: NotificationType) =>{
    add(content, type);
  };

  return { addNotification };
}

export default useNotification;