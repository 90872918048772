import React, { useContext } from 'react';
import { DeviceContextConsumer, DeviceType } from "../contexts/DeviceContext";
import sizeMe from 'react-sizeme';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { Title as ContactFormTitle } from "../screens/AskScreen";
import { IconButton } from '@material-ui/core';
import { ContactContext } from "../contexts/ContactContext";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import useTheme from "@material-ui/core/styles/useTheme";
import { useRef } from 'react';
import useHover from '../hooks/useHover';
import useTouched from '../hooks/useTouched';

function AskForm(props: any) {
  const { show } = useContext(ContactContext);
  const theme = useTheme();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const isTouched = useTouched(hoverRef);

  return (
          <DeviceContextConsumer>
            {context => (
                    <IconButton 
                    {...props}
                      size={context === DeviceType.isDesktopOrLaptop ? "medium" : "small"}
                      color="primary"
                      onClick={()=>{
                        show();
                      }}>
                        <div 
                        ref={hoverRef}
                        style={{
                          WebkitTapHighlightColor: 'transparent',
                          opacity: context === DeviceType.isDesktopOrLaptop ? (isHover ? '0.67' : '1') : isTouched ? '0.67' : '1',              
                          display: 'flex', 
                          flexDirection:'column',
                          backgroundColor: 'transparent',
                          alignItems: 'center'}}>
                          <ContactSupportIcon style={{
                            color: `${theme.palette.common.black}`
                            }}/>
                          <div 
                            style={{
                              whiteSpace: 'pre-line',
                              fontFamily: 'Signika',
                              color: `${theme.palette.common.black}`,
                              fontSize: context === DeviceType.isDesktopOrLaptop ? 10 : 8
                          }}>
                            {ContactFormTitle.replace(" ", "\n")}
                          </div>
                        </div>
                    </IconButton>              
            )}
          </DeviceContextConsumer>
  );
}

export const StyledLink = styled(Link)
`
  text-decoration: none;
  color: inherit;
  :hover, :active {
    color: inherit;
 }
`;

export default sizeMe({ monitorHeight: true })(AskForm);
