import './App.css';
import React from 'react';
import { MainLayout } from './components/layouts/MainLayout';
import { BrowserRouter as Router } from 'react-router-dom';
import CustomMuiThemeProvider from "./customTheme";
import { DeviceContextProvider } from './components/contexts/DeviceContext';
import { ContactContextProvider } from './components/contexts/ContactContext';
import { AddressContextProvider } from './components/contexts/AddressContext';
import Routes from "./components/routes/Routes";
import { NotificationContextProvider } from './components/contexts/NotificationContext';

function App() {
  return (
    <div className="App">
      <CustomMuiThemeProvider>
        <NotificationContextProvider>
          <ContactContextProvider>
            <AddressContextProvider>
              <DeviceContextProvider>
                <Router>
                  <MainLayout>
                    <Routes/>
                  </MainLayout>
                </Router>
              </DeviceContextProvider>
            </AddressContextProvider>
          </ContactContextProvider>
        </NotificationContextProvider>
      </CustomMuiThemeProvider>
    </div>
  );
}

export default App;
