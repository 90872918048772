import React from 'react';
import { lazy, Suspense } from 'react';
import { LoadingInProgress } from '../molecules/LoadingInProgress';
import { ContentLayout2 } from '../layouts/MainLayout';

const AskScreenContent = lazy(() => import("../common/AskScreenContent"));

export const Title = "Formularz zapytania".toUpperCase();

export function AskScreen(){
    return (
        <Suspense fallback={
           <LoadingInProgress/>
        }>
            <ContentLayout2>
                <AskScreenContent/>
            </ContentLayout2>
        </Suspense>
    );
}