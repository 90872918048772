import React from 'react';
import { lazy, Suspense } from 'react';
import { ContentLayout2 } from '../layouts/MainLayout';
import { LoadingInProgress } from '../molecules/LoadingInProgress';

const ContactScreenContent = lazy(() => import("../common/ContactScreenContent"));

export const Path = "/contact";
export const Title = "KONTAKT DO NAS";

export function ContactScreen(){
    return (
        <Suspense fallback={
            <LoadingInProgress/>
        }>
            <ContentLayout2>
                <ContactScreenContent/>
            </ContentLayout2>
        </Suspense>
    );
}