import Header from "../molecules/Header";
import { Footer } from "../molecules/Footer";
import { useState } from "react";
import React, { useContext } from "react";
import Drawer from "@material-ui/core/Drawer";
import { AskScreen } from "../screens/AskScreen";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ContactContext } from "../contexts/ContactContext";
import { DeviceContextConsumer, DeviceType } from "../contexts/DeviceContext";
import { FloatingFacebookLink } from "../molecules/FloatingFacebookLink";
import { AddressContext } from "../contexts/AddressContext";
import { ContactScreen } from "../screens/ContactScreen";
import { NotificationContainer } from "../contexts/NotificationContext";
import { useMediaQuery } from 'react-responsive';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { HomePath } from "../routes/Routes";

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const MainLayout = (props : any) =>  {
    const [paddingTop, setPaddingTop] = useState<number>(10);
    const { innerHeight: height, innerWidth: width } = window;

    const isPortrait = useMediaQuery({ orientation: 'portrait' });
    const prevVal = usePrevious(isPortrait);
    const location = useLocation();
    const history = useHistory();

    const {isOpen, hide} = useContext(ContactContext);
    const {isAddressOpen, hideAddress} = useContext(AddressContext);

    useEffect(()=>{
      if(location.pathname === HomePath){
        return;
      }

      history.push(location.pathname);
    }, [window.screen.width, window.screen.height]);

    useEffect(()=>{
      if(prevVal === undefined){
        return;
      }

      if(location.pathname === HomePath){
        return;
      }

      if(isPortrait !== prevVal){
        history.push(location.pathname);
      }
    }, [isPortrait, prevVal]);

    return (
    <DeviceContextConsumer>
      {context => 
        <>
          <Header onSize={(size: any)=>{
              setPaddingTop(size.height || 0);
          }} />
          <FloatingFacebookLink 
            top={context === DeviceType.isDesktopOrLaptop ? (height - paddingTop)*1/2 : (height - paddingTop)*2/3}
            left={window.innerWidth}
            href="https://pl-pl.facebook.com/pages/category/Advertising-Agency/Profipromo-148098065835251/"/>
          <div style={{
              height: height - paddingTop,
              width: '100%',
              paddingTop: paddingTop,
              overflow: 'hidden',
            }}>
               <NotificationContainer 
                style={{position:'absolute', 
                left: '50%', 
                top: paddingTop, 
                height: '10%',
                zIndex: 1000,
                backgroundColor: 'transparent',
                paddingTop: context === DeviceType.isDesktopOrLaptop ? '20px' : '10px',
              }}/>
               {props.children}
                <Drawer anchor={"bottom"} open={isOpen()}>
                  <ClickAwayListener onClickAway={(event: any)=>{
                    hide();
                  }}>
                    <div style={{
                      height: context === DeviceType.isDesktopOrLaptop ? 0.6*height : 0.8*height,
                    }}>
                      <AskScreen/>
                    </div>
                  </ClickAwayListener>
                </Drawer>
                <Drawer anchor={"right"} open={isAddressOpen()} style={{
                  height: height - paddingTop,
                  paddingTop: paddingTop,
                  backgroundColor: 'tansparent !important'
                }}>
                  <ClickAwayListener onClickAway={(event: any)=>{
                    hideAddress();
                  }}>
                    <div style={{
                      height: height - paddingTop,
                      backgroundColor: 'tansparent',
                      width: context === DeviceType.isDesktopOrLaptop ? 0.20*width : 0.60*width,
                    }}>
                      <ContactScreen/>
                    </div>
                  </ClickAwayListener>
                </Drawer>
              <Footer/>
          </div>
        </>
      }
    </DeviceContextConsumer>
  );
}

export const ContentLayout = (props: any) => {
  return (
    <div {...props} style={{
      display: 'flex', 
      flexDirection:'column', 
      width:'inherit',
      height: 'inherit',
      alignItems: 'center', 
      justifyContent: 'center',
      backgroundColor: 'tansparent',
      paddingBottom: '10px',
      transition: 'all 0.4s ease',
      msTransition: 'all 0.4s ease',
      MozTransition: 'all 0.4s ease',
      WebkitTransition: 'all 0.4s ease',
      }}>
      {props.children}
    </div>
    );
}

export const ContentLayout2 = (props: any) => {
  return (
    <div {...props} style={{
      display: 'flex', 
      flexDirection:'column', 
      width:'inherit',
      height: 'inherit',
      alignItems: 'stretch', 
      justifyContent: 'stretch',
      backgroundColor: 'tansparent',
      transition: 'all 0.4s ease',
      msTransition: 'all 0.4s ease',
      MozTransition: 'all 0.4s ease',
      WebkitTransition: 'all 0.4s ease',
      }}>
      {props.children}
    </div>
  );
}
