import React, { useEffect, useState } from 'react';

type Contact = {
    show: () => void;
    hide: () => void;
    isOpen: () => boolean;
};

const ContactContext = React.createContext<Contact>({} as Contact);

const ContactContextProvider = (props: any) => {
    const [ isBottomOpened, setIsBottomOpened ] = useState<boolean>(false);

    useEffect(()=>{
      setIsBottomOpened(false);
    }, []);

    const contactContext: Contact = ({
      show: () => {
        setIsBottomOpened(true);
      },
      hide: () => {
        setIsBottomOpened(false);
      },
      isOpen: () => {
        return isBottomOpened;
      }
    });
  
    return <ContactContext.Provider value={contactContext}>{props.children}</ContactContext.Provider>;
  }

export { ContactContextProvider, ContactContext };